html {
	font-family: $fontFamilyBody;
	font-weight: 300;
	font-size: 14px;
	line-height: 1;
	color: $colorDefaultText;
	user-select: none;
	/* TODO Eanble text selection styling on some elements? */
}

/* ============================================================
				Inline Styles
============================================================ */
b, strong {
	font-weight: 700;
}
i, em {
	font-style: italic;
}
small {
	font-size: 0.8em;
}
sup, sub {
	line-height: 0;
}
.u-noWrap {
	white-space: nowrap;
}
.u-avoidWrap {
	white-space: nowrap;
	@media (width <= 370px) {
		white-space: initial;
	}
}

/* Text Selection
================================================================================== */
::selection {
	background-color: #faefa0;
	color: $colorBlack;
}


/* ============================================================
				Headers
============================================================ */
h1, h2, h3, h4, h5, h6 {
	color: $colorBlue;
	font-weight: 300;
	line-height: 1.25;
	margin-bottom: $paragraphMarginBottom;
}
h1.h1--cover {
	font-weight: 500;
}
h1 {
	font-weight: 300;
	font-size: 45px;
}
h2 {
	font-weight: 300;
	font-size: 26px;
}
h3 {
	font-weight: 400;
	font-size: 15px;
	color: $colorBlack;
}


/* ============================================================
				Paragraph
============================================================ */
p {
	line-height: 1.5;
	font-size: 14px;
	margin-bottom: $paragraphMarginBottom;
}

/* ============================================================
				Links and buttons
============================================================ */
button,
a,
a:link {
	cursor: pointer;
	color: $colorLinkDefault;
	text-decoration: none;
	transition: color 200ms $easeOut 0ms,
				background-color 200ms $easeOut 0ms;
}

a:visited {
	color: $colorLinkVisited;
}

button:hover,
a:hover,
a:focus {
	color: $colorLinkHover;
	transition: color 400ms $easeOut 0ms;
}
button:focus,
a:focus {
	outline: 1px dotted $colorLinkFocusOutline;
}
button:active,
a:active {
	outline: none;
	color: $colorLinkActive;
}


/* ============================================================
				Lists (UL and OL)
============================================================ */

$itemMarginBottom: calc($paragraphMarginBottom * 0.75);
$groupMarginBottom: calc($paragraphMarginBottom - $itemMarginBottom);

%listCols {
	/*
	$colGap: $unitPadding;
	width: calc(100% + ($colGap * 2));
	margin: * calc(-1 * $colGap);
	display: flex;
	flex-direction: row;
	& li {
		flex: 1;
		margin: * $colGap;
	}

	@media (width <= 660px) {
		flex-direction: column;
	}
	*/
	column-gap: $unitPadding;
	columns: 12em;
	& li {
		-webkit-transform: translate3d(0, 0, 0); /* Fixes safari bug with bullets not positioned in the columns. */
	}
}

%styleLists {
	& ul,
	& ol {
		list-style: outside none none;
		list-style: none;
		padding: 0 0 0 1em;
		margin-bottom: $groupMarginBottom;
		line-height: 1.25;

		&.listCols{
			@extend %listCols;
		}
	}
	& li {
		margin-bottom: $itemMarginBottom;
	}
	& ul li:first-child,
	& ol li:first-child {
		margin-top: 0;
	}
	& li ul,
	& li ol {
		margin-bottom: 0;
	}
	& p + ul,
	& p + ol {
		margin-top: calc($itemMarginBottom - $paragraphMarginBottom);
	}

	& ul > li:before,
	& ol > li:before {
		position: relative;
		color: $colorBlack;
		display: inline-block;
		padding-right: 0.25em;
		vertical-align: middle;
	}
	& ul > li:before {
		content: '•';
		font: 600 12px arial, sans-serif;
		line-height: inherit;
		text-align: center;
		width: 1em;
		margin-left: calc(-1 * @width);
	}
	& ol {
		counter-reset: section;
	}
	& ol > li:before {
		content: counters(section, '.', decimal) '.';
		font-family: inherit;
		font-size: 0.95em;
		counter-increment: section;
		text-align: right;
		width: 2.5em;
		margin-left: calc(-1 * @width);
		font-weight: 600;
	}

	& li li ol {
		padding-left: 3em;
	}
	& li li ol > li:before {
		width: 3.5em;
		margin-left: calc(-1 * @width);
	}

	& li li li ol {
		padding-left: 4em;
	}
	& li li li ol > li:before {
		width: 5em;
		margin-left: calc(-1 * @width);
	}
}


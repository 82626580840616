$modalContentPadding: calc($unitPadding * 1.5);
$modalCloserFromEdge: $unitPadding;
$modalCloserSize: 20px;
$modalCloserExtendAmount: 8px; 

$modal_transitionDuration: 350ms;

/* ============================================================
				Window and close button
============================================================ */
.modal {
	position: absolute;
	pointer-events: none;
}

.modal__content {
	display: flex;
	flex-direction: column;
	z-index: $zIndexModal;

	pointer-events: auto;

	overflow: hidden; /* So content that may hit the corner are rounded. */
	background-color: $colorWhite;
	box-shadow: 2px 2px 5px 4px color($colorBlack a(7%));
	
	visibility: hidden;
	opacity: 0;
	
	$transformFromEdge: calc($modalCloserFromEdge + ($modalCloserSize / 2));
	transform-origin: calc(100% - $transformFromEdge) calc($transformFromEdge);
	transform: none;
	
	& .modal__body {
		flex-grow: 1;
		display: flex;
		overflow-y: auto;
	}
}
@media (width > $screenWidth_linear) {
	.modal .modal__content {
		max-height: calc(100vh - ($vpPadding * 2));
		border-radius: 3px;
	}
}
@media (width <= $screenWidth_linear) {
	.modal:not(.modal--fullscreenOnSmallScreen) .modal__content {
		max-height: calc(50vh - $vpPadding);
	}
	.modal--fullscreenOnSmallScreen .modal__content {
		height: 100vh;
	}
}

/* Centered and not centered */
.modal {
	@media (width > $screenWidth_linear) {
		top: $vpPadding;
		right: $vpPadding;
		width: calc(33% - $vpPadding);
		min-width: 22em;
		max-width: 26em;
	}
	@media (width <= $screenWidth_linear) {
		&:not(.modal--fullscreenOnSmallScreen) {
			bottom: 0;
			left: 0;
		}
		width: 100%;
	}
}

.modal--center {
	@media (width > $screenWidth_linear) {
		width: 50%;
		max-width: 50em;
	}
}

/* ============================================================
				Closer - Upper right corner of modal
============================================================ */
.modal__closer {
	display: block;
	position: absolute;
	z-index: 501;
	width: $modalCloserSize;
	height: $modalCloserSize;

	top: $modalCloserFromEdge;
	right: $modalCloserFromEdge;
	
	pointer-events: auto;
	
	opacity: 0;
	transition: transform 200ms $easeOut 0ms,
				opacity 400ms $easeOut 0ms; /* Opacity used by PlaceInfo. */
	
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		background-color: $colorLinkDefault;
		width: 100%;
		height: 2px;
		top: 50%;
		transform-origin: 50% 50%;
		transition: background-color 200ms $easeOut 0ms,
					transform 200ms $easeOut 0ms;
	}
	&:before {
		transform: rotate(-45deg);
	}
	&:after {
		transform: rotate(45deg);
	}

	&:hover:before,
	&:hover:after {
		background-color: $colorLinkHover;
	}
	&:active {
		transform: scale(0.9);
	}
	&:active:before,
	&:active:after {
		background-color: $colorLinkActive;
	}

	& .modal__closerIcon {
		/* [i] Info icon shown when minimized. */
		position: absolute calc(-1 * $modalCloserExtendAmount);
		z-index: 1;
		display: flex;

		background-color: $colorContainerBg;
		border: 1px solid $colorContainerBorder;

		& svg {
			display: block;
			width: 100%;
			height: 70%;
			margin: auto;
		}

		visibility: hidden;
		opacity: 0;
		transform: scale(0.6);
		transition: visibility 0ms $easeOut $modal_transitionDuration,
					opacity $modal_transitionDuration $easeOut 0ms,
					transform $modal_transitionDuration $easeMaxOut 0ms;
	}
}

/* ============================================================
	isActive and transitioning
============================================================ */
@media (width > $screenWidth_linear) {
	.modal--center.modal--isActive:not(.modal--isMinimized) {
		transform: translate($vpPadding, calc(-1* $vpPadding)) translate(-50vw, 50vh) translate(50%, -50%); /* Positioning form upper right hand corner. */
	}
}

.modal {
	opacity: 0;
	/* Transition of properties need to end at same time so JS's transitioend is called at one correct time. */
	transition: transform $modal_transitionDuration $easeOut 0ms,
			opacity $modal_transitionDuration $easeOut 0ms;
}
.modal--isActive {
	visibility: visible;
	opacity: 1;
	z-index: $zIndexModal;

	& .modal__content {
		pointer-events: initial;
		visibility: visible;
		opacity: 1;
		transform: none;
	}
	& .modal__closer {
		opacity: 1;
	}
}

.modal--center:not(.modal--isMaximizing):not(.modal--isMinimizing) {
	/* Only fade in when the transition is not for maximizing or minimizing. */
	transition: opacity calc($modal_transitionDuration * 1.5) $easeOut 200ms;
	& .modal__content {
		transition: none;
	}
}

/* ============================================================
	isMinimized and transitioning
============================================================ */
$modalContentOpacityDuration: calc($modal_transitionDuration * 0.75);
$modalContentOpacityDelay: calc($modal_transitionDuration - $modalContentOpacityDuration);

.modal__content {
	transition: visibility 0ms linear 0ms,
				opacity $modalContentOpacityDuration $easeOut $modalContentOpacityDelay,
				transform $modal_transitionDuration $easeMaxOut 0ms;
}
.modal--isMinimized {
	pointer-events: none;

	@media (width > $screenWidth_linear) {
		$translateAmount: calc($modalCloserFromEdge - $modalCloserExtendAmount);
		transform: translate($translateAmount, calc(-1 * $translateAmount));
	}
	@media (width <= $screenWidth_linear) {
		transform: translate(0, calc(100% - $modalCloserSize - ($modalCloserFromEdge * 2)));
	}

	& .modal__content {
		transform: scale(0.01);
		opacity: 0;
		transition: opacity $modalContentOpacityDuration $easeOut 0ms,
					transform $modal_transitionDuration $easeIn 0ms;
	}

	& .modal__closerIcon {
		visibility: visible;
		opacity: 1;
		transform: none;
		transition: visibility 0ms $easeOut 0ms,
					opacity $modal_transitionDuration $easeOut 0ms,
					transform $modal_transitionDuration $easeMaxOut 0ms;
	}
}

/* ============================================================
				Content
============================================================ */
.modal__body {
	overflow: auto;
}
.modal__body > .part {
	@media (width > $screenWidth_linear) {
		padding: calc($modalContentPadding * 1.2);
		padding-bottom: 0;
	}
	@media (width <= $screenWidth_linear) {
		padding: calc($modalContentPadding * 1);
	}

	display: none;
	width: 100%;

	&.isActive {
		display: block;
	}
}
.modal__bodyImageContainer {
	margin: auto 0;
	padding: 0 0 calc($unitPadding / 2) $unitPadding;
	float: right;
	max-width: calc(45% - ($unitPadding / 2));
	& img {
		max-width: 100%;
	}
}
.modal__bodyTextContainer {
	flex: auto;
	user-select: auto;

	@extend %styleLists;

	& ul:last-child,
	& ol:last-child,
	& p:last-child {
		margin-bottom: 0;
	}
	
	& > *:last-child {
		padding-bottom: calc($modalContentPadding * 1.25);
	}
}

.modal__link {
	flex: auto;
	padding: calc($unitPadding / 2) 0;
	font-weight: 400;

	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 1;

	&:before,
	&:after {
		display: block;
	}
}
.modal__linkIcon {
	display: inline-block;
	vertical-align: middle;
	margin-top: -0.25ex;
	width: 1em;
	height: 1em;
	margin-right: 0.5em;
	color: $colorBlack; /* Set the 'currentColor' for fill in the svg. */
}

/* ============================================================
				Footer
============================================================ */
.modal__footer {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	background-color: $colorGrey_10;
}
.modal__footer--disabled {
	display: none;
}
.modal__previous,
.modal__next {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	text-transform: uppercase;
	line-height: 1;
	font-weight: 500;

	white-space: nowrap;

	padding: $unitPadding calc($modalContentPadding * 0.5);
	flex-grow: 1;
	flex-basis: 1em;
	width: 34%;
	
	&:before,
	&:after {
		display: block;
	}
}

.modal__previous {
	background-color: $colorGrey_15;
	&:before {
		@extend %iconChevLeft;
	}
	&:hover {
		background-color: color($colorGrey_15 l(+3%));
	}
	&:hover:before {
		@extend %iconChev--hover;
	}
	&:active {
		background-color: color($colorGrey_15 b(+5%));
	}
	&:active:before {
		@extend %iconChev--active;
	}
	&:after {
		padding-left: 0.5em;
		content: "Previous";
	}
}
.modal__next {
	background-color: $colorBlue;
	color: $colorLinkDefault--onBlue;
	
	&:before {
		padding-right: 0.5em;
	}
	&:after {
		@extend %iconChevRight;
		@extend %iconChev--onBlue;
	}
	&:hover {
		background-color: color($colorBlue s(+10%) l(+2%));
		color: $colorLinkHover--onBlue;
	}
	&:hover:after {
		@extend %iconChev--onBlueHover;
	}
	&:active {
		background-color: color($colorBlue s(+10%) b(+10%));
		color: $colorLinkActive--onBlue;
	}
	&:active:after {
		@extend %iconChev--onBlueActive;
	}
}
.modal__next {
	&:before {
		content: "Next";
	}
	&[data-variation='startTour']:before {
		content: "Start Tour";
	}
	&[data-variation='backOut']:before {
		content: "Back Out";
	}
}

.modal__next--disabled,
.modal__previous--disabled {
	visibility: hidden;
}


/* ============================================================
				Media query
============================================================ */
@media (width <= 740px) {
	.modal {
		max-width: none;
		border-radius: 0;
	}
}


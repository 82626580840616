.part--twoWorld,
.part--world {
	display: flex;
	position: absolute;
	z-index: $zIndexWorldInfo;
	
	top: 0;
	width: 100%;
	pointer-events: none;

	$durationIn: 1000ms;
	$durationOut: 300ms;

	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	transition: transform $durationOut $easeOut $durationOut,
				visibility 0ms $easeOut $durationOut,
				opacity $durationOut $easeOut 0ms;
	
	&.isActive {
		opacity: 1;
		visibility: visible;
		transform: none;
		transition: transform $durationIn $easeMaxOut 0ms,
					visibility 0ms $easeOut 0ms,
					opacity $durationIn $easeMaxOut 0ms;
	}
}

.part--twoWorld {
	min-height: 52vh;
}
.part--world {
	min-height: 45vh;
}

.worldInfo {
	margin: auto;
	padding: calc($unitPadding * 4) calc($unitPadding * 2);
	width: calc(100% - ($clearfieldLogoWidth * 2));
	max-width: 60em;
	
	@media ($screenWidth_is_linear) {
		width: 100%;
	}
	@media (width >= 1000px) {
		width: calc(100% - ($clearfieldLogoMaxWidth * 2));
	}

	text-align: center;

	& p {
		font-size: 15px;
		font-weight: 400;
		color: $colorGrey_80;
	}

	& h1:last-child,
	& h2:last-child,
	& h3:last-child,
	& p:last-child,
	& ul:last-child,
	& ol:last-child {
		margin-bottom: 0;
	}
}


%iconChevLeft,
%iconChevRight {
	display: block;
	content: '';
	width: 0.5em;
	height: @width;
	border-style: solid;
	transform-origin: 50% 50%;
	transform: rotate(45deg);
	transition: border-color 200ms $easeOut 0ms,
				transform 200ms $easeOut 0ms;
}

%iconChevLeft {
	border-width: 0 0 2px 2px;
}
%iconChevRight {
	border-width: 2px 2px 0 0;
}

/* default on grey / white */
%iconChevLeft,
%iconChevRight {
	border-color: $colorLinkDefault;
}
%iconChev--hover {
	border-color: $colorLinkHover;
}
%iconChev--active {
	border-color: $colorLinkActive;
}

/* on blue */
%iconChev--onBlue {
	border-color: $colorLinkDefault--onBlue;
}
%iconChev--onBlueHover {
	border-color: $colorLinkHover--onBlue;
}
%iconChev--onBlueActive {
	border-color: $colorLinkActive--onBlue;
}
html, body {
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

html {
	background: $colorHtmlBg;
}

#contentWrap {
	position: absolute 0;
	overflow: hidden;
}

#renderCanvas {
	width: 100%;
	height: 100%;
	position: absolute;
	touch-action: none;
}

.part {
	display: none;
}

#LinkNodes, #data {
	position: absolute;
	width: 100%;
}


/* ==================================================================
 * Offline vs Online
 * ================================================================== */
a.isOffline	{	display: none; }
body.isOffline {
	& a.isOnline	{	display: none}
	& a.isOffline	{	display: initial; }
}

/*
	Fonts subest using: https://www.fontsquirrel.com/tools/webfont-generator
	Syntax used for @font-face is based on: https://css-tricks.com/snippets/css/using-font-face/
*/


/* ============================================================
	Montserrat (https://github.com/JulietaUla/Montserrat)
 ============================================================ */
/* ===== Light (300) === */
@font-face {
	font-family: 'Montserrat-WF';
	src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
		 url('../fonts/Montserrat-Light.woff') format('woff'),
		 url('../fonts/Montserrat-Light.ttf') format('truetype');
	font-weight : 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-WF';
	src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
		 url('../fonts/Montserrat-Light.woff') format('woff'),
		 url('../fonts/Montserrat-Light.ttf') format('truetype');
	font-weight : 300;
	font-style: italic;
}

/* ===== Regular (400) === */
@font-face {
	font-family: 'Montserrat-WF';
	src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
		 url('../fonts/Montserrat-Regular.woff') format('woff'),
		 url('../fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight : 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat-WF';
	src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
		 url('../fonts/Montserrat-Italic.woff') format('woff'),
		 url('../fonts/Montserrat-Italic.ttf') format('truetype');
	font-weight : 400;
	font-style: italic;
}

/* ===== Medium (500) === */
@font-face {
	font-family: 'Montserrat-WF';
	src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
		 url('../fonts/Montserrat-Medium.woff') format('woff'),
		 url('../fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight : 500;
	font-style: normal;
}

/* ===== Bold (700) === */
@font-face {
	font-family: 'Montserrat-WF';
	src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
		 url('../fonts/Montserrat-Bold.woff') format('woff'),
		 url('../fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight : 700;
	font-style: normal;
}


.toolbar {
	display: flex;

	position: absolute;
	bottom: 0;
	right: 0;

	border: 1px solid #CCC;
	background: white;
	padding: 5px;

	&:before {
		content: '';

		width: 100%;
		height: 30px;

		position: absolute;
		top: 0;
		right: 0;
		transform: translateY(-100%);
	}

	transition: transform 300ms 500ms;
	transform: translateY(100%);
	&:hover {
		transition: transform 300ms 2000ms;
		transform: translateY(0px);
	}
}
.toolbar__link {
	padding: 4px 6px;
	background-color: $colorWhite;
	text-transform: uppercase;
	font-weight: 500;
	margin: 0 10px;

	&:not(.toolbar__link--active) {
		color: grey;
	}
}
$localNav_bgColor: color($colorContainerBg a(90%));
$localNav_borderColor: $colorContainerBorder;

.localNavigation {
	transform: translate3d(0, 0, 0); /* Cause a render layer. */
	pointer-events: none;

	display: flex;
	flex-direction: column;

	position: absolute;

	z-index: $zIndexLocalNav;
	@media (width <= $screenWidth_linear) {
		z-index: $zIndexLocalNav--mobile;
	}

	top: $vpPadding;
	left: $vpPadding;
	max-width: 300px;
	width: calc(100vw - ($vpPadding * 2));
	max-height: calc(100vh - ($vpPadding * 2));

	opacity: 0;
	visibility: hidden;
	transition: visibility 0ms $easeOut 200ms,
				opacity 200ms $easeOut 0ms;
	
	&.localNavigation--everything,
	&.localNavigation--isVisible {
		opacity: 1;
		visibility: visible;
		transition: visibility 0ms $easeOut 0ms,
					opacity 200ms $easeOut 0ms;
	}

	@media ($screenWidth_is_linear) {
		$vpPadding: 0;
		top: $vpPadding;
		left: $vpPadding;
		max-width: none;
		width: 100vw;
		max-height: 100vh;
	}
}

/* ============================================================
				Header
============================================================ */
.localNavigation__header {
	pointer-events: initial;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	/* The header and body overlap by 0.5px, and IE11 was layering
	them wrong. Next two lines addressed this IE11 bug. */
	position: relative;
	z-index: 1;
}
.localNavigation__back,
.localNavigation__close {
	font-weight: 500;
	font-size: 11px;
	text-transform: uppercase;

	transition: visibility 0ms $easeOut 0ms,
				opacity 200ms $easeOut 0ms;
}
.localNavigation:not(.localNavigation--isVisible),
.localNavigation--hideLinks:not(.localNavigation--everything) {
	& .localNavigation__body > ul {
		display: none;
	}
	& .localNavigation__close {
		transition: visibility 0ms $easeOut 200ms,
					opacity 200ms $easeOut 0ms;

		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}
}
.localNavigation__backWrapper {
	flex: auto;
	padding: 4px 3px 3px 0;
	margin-left: 3px;
	border-bottom: 1px solid color($localNav_borderColor a(0%));
	transition: border-color 50ms linear 0ms;
}
.localNavigation__close {
	display: flex;
	flex-direction: row;
	padding: calc($unitPadding / 2) $unitPadding;
	align-items: center;

	background-color: $localNav_bgColor;
	@media ($screenWidth_isNot_linear) {
		border: 1px solid $localNav_borderColor;
		border-radius: 3px;
	}
}
button.localNavigation__close { /* need to increase specificty */
	transition: border-color 200ms $easeOut 200ms,
				color 300ms $easeOut 0ms;
}

.localNavigation__backLabel,
.localNavigation__closeLabel {
	display: block;
	margin: auto 0;
}
.localNavigation__closeLabel {
	position: relative;

	&:before,
	&:after {
		width: 100%;
		display: inline-block;
		text-align: center;
	}
	&:before {
		content: 'Close';
		visibility: hidden;
		opacity: 0;
		transition: visibility 0ms $easeOut 200ms,
					opacity 200ms $easeOut 0ms;
	}
	&:after {
		content: 'Menu';
		position: absolute;
		left: 0;
		transition: visibility 0ms $easeOut 0ms,
					opacity 200ms $easeOut 0ms;
	}
}

.localNavigation__backIcon {
	display: block;
	padding-right: 0.5em;
	
	&:after {
		@extend %iconChevLeft;
		@extend %iconChev--onBlue;
	}
}
.localNavigation__back {
	@media ($screenWidth_isNot_linear) {
		width: 100%;
	}

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: calc($unitPadding / 2) $unitPadding;

	background-color: $colorBlue;
	border-radius: 3px;
	flex: auto;

	color: $colorLinkDefault--onBlue;
	&:hover { color: $colorLinkHover--onBlue; }
	&:hover .localNavigation__backIcon:after {
		@extend %iconChev--onBlueHover;
	}
	&:active { color: $colorLinkActive--onBlue; }
	&:active .localNavigation__backIcon:after {
		@extend %iconChev--onBlueActive;
	}
}


$closeIconWidth: 1px;
.localNavigation__closeIcon {
	width: 1em;
	height: calc(@width - 2px);
	position: relative;
	margin-right: 0.5em;
	overflow: hidden;

	/* Top, bottom and center bars */
	&:before,
	&:after,
	& span {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: $closeIconWidth;
		transform-origin: 50% 50%;
		background-color: $colorLinkDefault;
		transition: transform 200ms $easeOut 0ms,
					top 200ms $easeOut 0ms,
					bottom 200ms $easeOut 0ms;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}
	& span {
		top: 50%;
	}
}
.localNavigation__close {	
	color: $colorLinkDefault;
	&:hover {
		color: $colorLinkHover;
	}
	&:hover .localNavigation__closeIcon:before,
	&:hover .localNavigation__closeIcon:after,
	&:hover .localNavigation__closeIcon span {
		background-color: $colorLinkHover;
	}
	&:active {
		color: $colorLinkActive;
	}
	&:active .localNavigation__closeIcon:before,
	&:active .localNavigation__closeIcon:after,
	&:active .localNavigation__closeIcon span {
		background-color: $colorLinkActive;
	}
}

/* Open state */
.localNavigation--isOpen:not(.localNavigation--hideLinks) {
	& .localNavigation__backWrapper {
		border-bottom-color: $localNav_borderColor;
		transition: border-color 100ms $easeOut 0ms;
	}
}

.localNavigation--isOpen {
	.localNavigation__closeIcon {
		&:before,
		&:after,
		& span {
			transition: transform 150ms linear 0ms,
						top 150ms linear 20ms,
						bottom 150ms linear 20ms;
		}
		& span {
			transform: scale(0.01, 1);
		}
		&:before {
			top: calc(50% - ($closeIconWidth / 2));
			transform: rotate(-45deg);
		}
		&:after {
			bottom: calc(50% - ($closeIconWidth / 2));
			transform: rotate(45deg);
		}
	}

	& .localNavigation__close {
		border-radius: 3px 3px 0 0;
		border-bottom-color: rgba(0,0,0,0);
		transition: border-color 100ms $easeOut 0ms,
					color 300ms $easeOut 0ms;
	}

	.localNavigation__closeLabel {
		&:before { /* "Close" */
			visibility: visible;
			opacity: 1;
			transition: visibility 0ms $easeOut 0ms,
						opacity 200ms $easeOut 0ms;
		}
		&:after { /* "Menu" */
			visibility: hidden;
			opacity: 0;
			transition: visibility 0ms $easeOut 200ms,
						opacity 200ms $easeOut 0ms;
		}
	}
}


/* ============================================================
				Body
 ============================================================ */
.localNavigation__body {
	margin-top: -0.5px; /* removed a very faint line between the tab and body in Edge and IE11. */
	overflow: hidden;
	border-color: $localNav_borderColor;
	
	@media ($screenWidth_isNot_linear) {
		border-radius: 3px 0 3px 3px;
	}

	/* TODO Need?
	.localNavigation--hideLinks:not(.localNavigation--everything) & {
		transition: visibility 0ms $easeOut 200ms,
					opacity 200ms $easeOut 0ms;
		opacity: 0;
		visibility: hidden;
	}
	*/
	
	& .category {
		color: #000;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 500;
	}

	& li > a {
		display: block;
		color: $colorGrey_80;
	}
	& .node--isActive > a {
		color: $colorBlue;
	}
	& .node--annotationLabel {
		color: $colorOrange;
		margin-right: calc($unitPadding / 2);
		font-weight: 700;
	}
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;
		line-height: 1.25;
		font-weight: 300;
		font-size: 15px;
		& ul {
			font-size: 13px;
		}
	}
	& li {
		padding: calc($unitPadding / 2) 0 0 0;
		margin: 0;
	}

	& .node,
	& .category {
		display: none;
		&.node--isAncestorOfActive,
		&.node--isAncestorOfActive .node,
		&.node--isAncestorOfActive .category {
			display: list-item;
		}
	}

	& > ul {
		/* Zero Level - World (e.g. Telecom) */
		& > li {
			& > a {
				display: none;
			}
		}
	}

	& > ul > li > ul {
		padding: calc($unitPadding / 2) $unitPadding;

		/* First Level - Place (e.g. Single Family Home) */
		& > li {
			opacity: 0;
			transform: translateY(10px);
			transition: opacity 600ms $easeMaxOut 100ms,
						transform 400ms $easeMaxOut 100ms;
			
			font-size: 16px;
			& > a {
				color: #000;
				font-weight: 500;
			}

			visibility: hidden;
			height: 0;
			padding: 0;
			margin: 0;
			overflow: hidden;

			.localNavigation--everything &,
			&.node--isActive,
			&.node--isAncestorOfActive {
				visibility: visible;
				height: auto;
				display: list-item;
			}
		}
	}

	& > ul > li > ul > li > ul {
		/* Second Level - Product */
		& > .node {
			border-radius: 3px;
			background-color: $colorWhite;

			font-weight: 400;

			margin: calc($unitPadding / 2) 0;
			padding: calc($unitPadding * (3 / 4)) $unitPadding;
		}
	}

	& > ul > li > ul > li > ul {
		.localNavigation--everything & > li > ul,

		& li.node--isAncestorOfActive > ul,
		& li.node--isActive > ul
		/*& > li > ul > li.node--isActive ul*/
		{
			max-height: 500px;
		}

		& > li > ul {
			padding: 0 calc($unitPadding / 2);
			overflow: hidden;
			max-height: 0;
			transition: max-height 500ms $easeOut 50ms;

			&:before {
				content: '';
				display: block;
				border-top: 1px solid $colorGrey_10;
				margin-top: calc($unitPadding * (3 / 4));
			}

			/* Third Level */
			& > li {
				font-weight: 300;
			}
			& > li:first-child {
				padding-top: $unitPadding;
			}

			/* >=Fourth Level */
			& > li ul {
				margin-left: 1em;
				overflow: hidden;
				max-height: 0;
				transition: max-height 500ms $easeOut 50ms;
			}
		}
	}

}

.localNavigation--isOpen:not(.localNavigation--hideLinks) .localNavigation__body,
.localNavigation--isChangingOpenState .localNavigation__body {
	background-color: $localNav_bgColor;

	@media ($screenWidth_isNot_linear) {
		border: 1px solid $localNav_borderColor;
		border-top: none;
	}

	& > ul > li > ul > li {
		opacity: 1;
		transform: none;
	}
}
.localNavigation__body {
	pointer-events: initial;
	transition: height 300ms $easeOut 0ms,
		border-color 100ms linear 100ms,
		visibility 0ms $easeOut 0ms,
		opacity 200ms $easeOut 0ms;
}
.localNavigation--isOpen .localNavigation__body {
	transition: height 300ms $easeOut 0ms,
		border-color 100ms linear 100ms,
		visibility 0ms $easeOut 0ms,
		opacity 200ms $easeOut 0ms;
}
.localNavigation:not(.localNavigation--isOpen),
.localNavigation--isOpen.localNavigation--isChangingOpenState {
	& .localNavigation__body {
		pointer-events: none;
		overflow: hidden;
		border-bottom-color: color($localNav_borderColor a(0%));
	}
}

.localNavigation--everything {
	& .localNavigation__body {
		overflow: scroll;
	}
}
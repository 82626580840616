$logoFromEdge: $unitPadding;

a.clearfieldLogo {
	opacity: 0;
	transform: none;
	visibility: hidden;
	transition: visibility 0ms $easeOut 300ms,
				transform 300ms $easeOut 0ms,
				opacity 300ms $easeOut 0ms;

	&.clearfieldLogo--isVisible {
		opacity: 1;
		visibility: visible;
		transition: visibility 0ms $easeOut 0ms,
					transform 300ms $easeOut 0ms,
					opacity 300ms $easeOut 0ms;
	}

	cursor: pointer;

	position: absolute;
	right: $logoFromEdge;
	top: $logoFromEdge;
	display: block;
	z-index: $zIndexLogo;
	
	width:  $clearfieldLogoWidth;
	max-width: $clearfieldLogoMaxWidth;

	&:before {
		content: "";
		display: block;
		width: 100%;
		margin-bottom: 100%;
	}

	& svg {
		display: block;
		position: absolute;
		top: 0;

		width: 100%;
		height: 100%;
	}

	@media ($screenWidth_is_linear) {
		width: 100px;
	}
}


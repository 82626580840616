.linkNode {
	transform: translate3d(0, 0, 0); /* Cause a render layer. */
	
	position: absolute;
	left: 0;
	top: 0;
	z-index: 50;
	padding: 0;

	display: flex;
	flex-direction: row;
	width: 1px;
	height: 1px;
	justify-content: flex-start;
	align-items: center;

	/* For checking positioning:  outline: 1px solid blue; */
}

.linkNode__buttonIcon {
	/* Not used for most. */
	display: none;
}

.linkNode__buttonLabel {
	display: block;
	padding: 10px 16px 9px 16px;
}

.linkNode__button {
	/* For checking positioning:  opacity: 0.5; */
	position: relative;
	white-space: nowrap;
	padding: 0;

	cursor: pointer;

	font-weight: 500;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.02em;

	transform: none;

	&,
	& .linkNode__buttonLabel,
	& .linkNode__buttonIcon,
	&:before {
		background-color: color($colorOrange s(-2%) l(+1%));
		color: color($colorWhite a(0.95));
		transition: transform 400ms $easeOut 0ms,
					background-color 400ms $easeOut 0ms,
					color 400ms $easeOut 0ms;
	}

	&:hover {
		transform: translateY(-2px);

		&,
		& .linkNode__buttonLabel,
		& .linkNode__buttonIcon,
		&:before {
			background-color: color($colorOrange s(+27%) b(+11%));
			color: $colorWhite;
			transition: transform 200ms $easeOut 0ms,
						background-color 200ms $easeOut 0ms,
						color 200ms $easeOut 0ms;
		}
	}

	&:active {
		transform: scale(0.99);

		&,
		& .linkNode__buttonLabel,
		& .linkNode__buttonIcon,
		&:before {
			background-color: color($colorOrange s(+5%) b(+12%));
			color: $colorWhite;
			transition: transform 100ms $easeOut 0ms,
						background-color 100ms $easeOut 0ms,
						color 100ms $easeOut 0ms;
		}
	}
}

/* ============================================================
				Tool Tip
============================================================ */
.linkNode--place, .linkNode--modal {
	& .linkNode__button[data-title] {
		&:after {
			content: attr(data-title);
			
			font-size: 14px;
			text-transform: initial;
			color: #333;
			white-space: nowrap;

			position: absolute;
			left: 50%;
			top: 50%;
			padding: 4px 8px;

			z-index: 10;
			background: #ccc;

			opacity: 0;
			visibility: hidden;
			transition: visibility 0ms $easeOut 200ms,
						opacity 200ms $easeOut 0ms;
		}
		&:hover:after {
			opacity: 1;
			visibility: visible;

			$toolTipDelayDisplay: 750ms;
			transition: visibility 0ms $easeOut 0ms,
						opacity 200ms $easeOut $toolTipDelayDisplay;
		}
	}
}



.linkNode--place {
	& .linkNode__buttonIcon { /* (+) */
		display: block;
		font-weight: 700;
		display: block;
		font-size: 28px;
		width: 1.1em;
		height: @width;
		line-height: @height;
		border-radius: 50%;
		position: absolute;
		left: calc(-1 * @width / 2);
		top: calc(50% - (@height / 2));
		&:before {
			content: '+';
			display: block;
			width: 100%;
			text-align: center;
		}
	}
}

.linkNode--modal,
.linkNode--placeSection {
	/* Position origin along center bottom. */
	align-items: flex-end;
	& .linkNode__button {
		transform: translateX(-50%); /* I originally used the following on .linkNode--modal | placeSection but IE11 did not do what I wanted: justify-content: center; */
		&:hover {
			transform: translate(-50%, -3px);
		}
	}
}

.linkNode--world,
.linkNode--modal {
	$modalLabelArrowSize: 10px;

	& .linkNode__button {
		top: calc(-1 * $modalLabelArrowSize);
		
		&:before { /* Down triangle for [A], and world labels */
			content: '';
			width: $modalLabelArrowSize;
			height: $modalLabelArrowSize;
			position: absolute;
			z-index: -1;
			left: calc(50% - ($modalLabelArrowSize / 2));
			bottom: calc(-1 * ($modalLabelArrowSize / 2));
			transform-origin: 50% 50%;
			transform: rotate(45deg);
		}
	}
}

.linkNode--modal {
	& .linkNode__buttonLabel {
		padding: 12px 12px 10px 12px;
	}
}

.linkNode--world {
	& .linkNode__buttonLabel {
		font-size: 20px;
		padding: 12px 20px;
	}
}


.linkNode--place {
	& .linkNode__button {
		font-weight: 400;
		text-transform: none;
	}

	/* TODO Consider bringing this back and adding similar to [A]. For now I am just display none. */
	& .linkNode__buttonLabel {
		display: none;

		padding: 8px * 6px 25px; /* Due to the circle that is covering the left side. */
		font-size: 14px;
		background-color: $colorWhite;
		color: $colorDefaultText;
	}
}


/* ============================================================
 .isVisible, .isActive, and transitiong
 	isVisible gets added by setEnabled() and says it should be visible
 	isActive gets added by setActive() and says if it is active (aka highlighted currently)
============================================================ */
.linkNode {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0ms linear 50ms,
			opacity 50ms linear 0ms;
}
.linkNode.isVisible {
	visibility: visible;
	opacity: 1;
	transition: visibility 0ms linear 0ms,
				opacity 1000ms $easeOut 0ms;
}

.linkNode.isActive {
	.linkNode__button {
		& .linkNode__buttonLabel {
			transform: none;
		}
		& .linkNode__buttonLabel,
		& .linkNode__buttonIcon,
		&:before {
			background-color: $colorBlue;
			transition: transform 200ms $easeOut 0ms,
				color 200ms $easeOut 0ms;
		}
	}
}
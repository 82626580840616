
.optimizedForModernBrowser {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-style: italic;
	padding: 0 16em; /*Leave space for contact CTA button. */

	display: none;

	&--recommendOther {
		display: block;
	}

	@media (width <= 730px) {
		display: none;
	}
}

.modal--optimizedForModernBrowser {
	overflow: auto;

	& .part {
		padding-bottom: calc($unitPadding * 2);
		text-align: center;
	}

	&.modal--isActive {
		z-index: $zIndexOldBrowserModal;
	}

	& button {
		display: inline-block;
	
		text-transform: uppercase;
		line-height: 1;
		font-weight: 500;
	
		white-space: nowrap;
	
		padding: 8px 14px;
		
		border-radius: 3px;
		background-color: $colorBlue;
		color: $colorLinkDefault--onBlue;
		
		&:hover {
			background-color: color($colorBlue s(+10%) l(+2%));
			color: $colorLinkHover--onBlue;
		}
		&:active {
			background-color: color($colorBlue s(+10%) b(+10%));
			color: $colorLinkActive--onBlue;
		}
	}

	&:not(.optimizedForModernBrowser--canContinue) {
		& .optimizedForModernBrowserModal__closer,
		& .isCanBrowserContinue {
			display: none;
		}
	}
}

.optimizedForModernBrowserModal__logos {
	padding-top: calc($unitPadding * 0.75);
	padding-bottom: calc($unitPadding * 2);
	
	& img {
		margin: * calc($unitPadding * 0.5);
		width: 20%;
		display: inline-block;
		@media (width <= 800px) {
			width: 25%;
		}
	}
}
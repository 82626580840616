.clouds {
	position: absolute 0;
	overflow: hidden; /* Needed for Apple / Safari. */
	animation: 250s linear 0ms infinite normal cloudsMove;

	
	top: 0;
	bottom: 25%;
	left: 0;
	width: 200%;
	background-image: url('../images/design/clouds.jpg');
	background-size: 50% auto;
	background-repeat: repeat-x;

	&:before {
		content: "";
		display: block;
		position: absolute 0;
		top: 75%;
		bottom: 0;
		background: linear-gradient(180deg, color($colorHtmlBg a(0%)), $colorHtmlBg);
	}
}
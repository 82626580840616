@import 'normalize.css'; 

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,
address,code,del,dfn,em,img,q,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td {
	margin: 0;
	padding: 0;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	vertical-align: baseline;
}

figure {
	margin: 0;
}
a img {
	border: none;
}
a,
object {
	outline: none;
}

canvas:focus {
	outline: none;
}

button {
	border: none;
	font-family: inherit;
	background-color: inherit;
}

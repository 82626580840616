
$scrollbarControls-bgColor: $colorWhite;
$scrollbarThumb-bgColor: $colorGrey_30;


/* Native scrollbars: Chrome (webkit)
-------------------------------------------------------------- */

$scrollbarControls-size: 10px;
$scrollbarControls-borderSize: 3px;

::-webkit-scrollbar {
	width: $scrollbarControls-size;
	height: $scrollbarControls-size;
	background-color: $scrollbarControls-bgColor;
}
::-webkit-scrollbar-corner {
	background-color: color($scrollbarControls-bgColor l(-2%));
}

::-webkit-scrollbar-track {
	background-color: $scrollbarControls-bgColor;
	border: $scrollbarControls-borderSize solid $scrollbarControls-bgColor;
}

::-webkit-scrollbar-thumb {
	background-color: $scrollbarThumb-bgColor;
	border: $scrollbarControls-borderSize solid $scrollbarControls-bgColor;
}





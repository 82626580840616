a.globalCta {
	display: block;	
	position: absolute;
	z-index: $zIndexGlobalCta;
	bottom: $vpPadding;
	right: $vpPadding;
	/*
	font-weight : 500;
	color: $colorWhite;
	*/

	text-transform: uppercase;
	line-height: 1;
	font-weight: 500;

	white-space: nowrap;

	padding: 8px 12px;
	
	border-radius: 3px;
	background-color: $colorBlue;
	color: $colorLinkDefault--onBlue;
	
	&:hover {
		background-color: color($colorBlue s(+10%) l(+2%));
		color: $colorLinkHover--onBlue;
	}
	&:active {
		background-color: color($colorBlue s(+10%) b(+10%));
		color: $colorLinkActive--onBlue;
	}

	@media ($screenWidth_is_linear) {
		display: none;
	}
}
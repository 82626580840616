.loadingScreen {
	position: absolute 0;
	z-index: $zIndexLoadingScreen;
	background: $colorBlack;
}
.loadingScreen__image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 100%;
	max-width: calc(100vmin - ($unitPadding * 2));
	color: $colorWhite;
	
	opacity: 0;
	transition: opacity 750ms $easeOut 0ms;
	
	& img {
		margin-bottom: 22%;
		max-width: 100%;
	}
	& svg {
		display: block;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 88%;
		color: white;
	}
}

.loadingScreen.isLoading .loadingScreen__image {
	opacity: 1;
}

.loadingScreen.isLoaded {
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	
	$duration: 750ms;
	transition: visibility 0ms $easeOut $duration,
				opacity $duration $easeOut 0ms;
}